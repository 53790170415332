var site = site || {};

(function ($) {
  site.offers = site.offers || {};
  site.offers.welcomeoffer = site.offers.welcomeoffer || {};

  // --------
  // Primary behavior responsible for initializing the welcome offer logic
  var deferredObjects = [];

  Drupal.behaviors.ELB_welcomeoffer = {
    // debug config
    debug: false,
    showThanks: true,
    showSignedUp: false,

    // storage cookie name
    offerCookie: 'pc_email_optin',

    // # of seconds before the overlay appears
    defaultTimeout: 3000,

    // template path config
    templates: {
      form: 'email_sms_signup',
      thanks: 'email_sms_signup_success'
    },

    // overlay config
    overlay: {
      pc: {
        className: 'welcomeoffer-overlay',
        transition: 'none',
        width: 'auto',
        height: 'auto'
      },
      mobile: {
        className: 'welcomeoffer-overlay',
        width: '100%',
        height: '100%',
        opacity: '0',
        transition: 'none'
      }
    },

    isStr: function (str) {
      return typeof str === 'string';
    },

    isFunc: function (func) {
      return typeof func === 'function';
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }

      return mixed_var !== null && typeof mixed_var === 'object';
    },

    /**
     * Helper function to get the raw contents of an JS inline template, and optionally interpolate
     * the html using Mustache
     */
    getTemplateContent: function (key, data) {
      var undef;

      // Sanitize the incoming data
      path = key !== undef ? key : 'foobar_template';
      data = data || false;

      // Get the html content of the template
      var html = site.template.get({
        name: key,
        data: data
      });

      if (html.length == 0) {
        return $();
      }

      // If there's no data object passed in, then just return a basic jquery element
      if (data == false || !$.isFunction(Mustache.render)) {
        return $(html);
      }

      // We have html and data, which means we should use Mustache to render the output
      return $(Mustache.render(html, data));
    },

    // Open a colorbox window
    openWindow: function (content, callBacks) {
      var self = this;

      content = self.isObj(content) ? content : $();

      // Dumb trick to get the entire contents of the html out of the jquery object
      content = content.wrap('<div />').parent().html();

      if (!$.isFunction($.colorbox)) {
        return false;
      }

      var args = {
        html: content,
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%'
      };
      var darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;

      $.extend(args, darg);

      if (self.isObj(callBacks)) {
        for (var k in callBacks) {
          var func = callBacks[k];

          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }

      $.colorbox(args);
    },

    // Show the signup form
    showSignupForm: function () {
      var self = this;

      setTimeout(function () {
        var content = self.getTemplateContent(self.templates.form);
        var useAltMarkUp = $(content).find('#USE_ALT_LAYOUT').length;

        if (useAltMarkUp) {
          // TODO: should cboxArgs be global?
          cboxArgs = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
          cboxArgs.className = 'welcomeoffer-overlay alt_layout1';
          cboxArgs.fixed = true;
        }

        self.openWindow(content, {
          // Hide the content and overlay in onOpen, so we can fade it in initSignUpForm
          // This prevents "sliding" while the overridden css is loading, and also makes the overlay and content fade in simultaneously
          onOpen: function () {
            $('.welcomeoffer-overlay #cboxContent, #cboxOverlay').css('visibility', 'hidden');
          },
          onComplete: function () {
            site.offers.welcomeoffer.initSignUpForm();
            // This fixes a bug on the m. domain where the colorbox
            // would take up the full height on mobile devices.
            if ($('body').hasClass('device-mobile')) {
              $.colorbox.resize();
            }

            // TODO: this needs to use an event, not call the behavior
            Drupal.behaviors.adaptivePlaceholders.attach($('#cboxLoadedContent'));
          }
        });
      }, self.defaultTimeout);
    },

    /**
     * Helper function to determine if the welcomeoffer offer should appear on this page
     * @TODO: this will likely be expanded to be disabled on checkout and whatnot
     */
    showOfferOnPage: function () {
      var self = this,
        cookieName = self.offerCookie,
        hasCookie = $.cookie(cookieName),
        showOffer = false,
        undef;
      // adding to allow manual disabling of the popover
      var disabled = 0;

      if (hasCookie == undef) {
        // there is a div in the mustache that gets printed based on the disabled checbox input email_sms_signup_v1
        var markUp = self.getTemplateContent(self.templates.form);
        var markUpDisabled = $(markUp).find('#DISABLE_POPOVER');

        // set the session cookie if the page is disabled, rather than checking every time
        if (markUpDisabled.length > 0) {
          $.cookie(cookieName, '1', {
            expires: 14,
            path: '/'
          });

          return false;
        }
      }

      if (self.debug || hasCookie == undef) {
        showOffer = true;
      }

      return showOffer;
    },

    // Main function to get things kicked off
    attach: function (context, settings) {
      var self = this,
        cookieName = self.offerCookie;

      // do not show on access denied page
      if ($('body').hasClass('content-gate-access-denied-page')) {
        return;
      }
      // If the user hasn't seen the popup, then show it to them
      if (self.showOfferOnPage()) {
        this.showSignupForm();
      }

      // Create the cookie
      $.cookie(cookieName, '1', {
        expires: 14,
        path: '/'
      });

      // Bind to the email_signup.success event
      // When a user does the email sign up in the footer, we'll show them the welcomeoffer overlay
      // if the offer is still valid, and they haven't signed up already
      $(context).on('email_signup.success', function (event, rpcResponse) {
        var response = rpcResponse.getData(),
          userinfo = response.userinfo || {},
          templates = self.templates,
          content = '';
        var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;

        content = templates.thanks;
        if (content.length > 0) {
          // @TODO: triggering an overlay will close the special-offers-tray. We don't want it to do that.
          var html = self.getTemplateContent(content);

          self.openWindow(html, {
            onComplete: function () {
              $.colorbox.resize();
            }
          });
        }
      });

      // Bind to the email_signup.error event for no particular reason except that it exists
      // Do we need to show an error message?
      $(context).on('email_signup.error', function (event, rpcResponse) {});
      // Lets check the settings to get the INTEREST SECTION INPUT NAMES which should be used to display the User Interest Options (different between locales).
      var interestSectionInputNames =
        Drupal.settings.common.interest_sections_input_names || 'PERFUME_AS_AN_ART,KILIAN_MY_KIND_OF_LOVE';
      var attrNames = interestSectionInputNames.trim().split(',');

      $.each(attrNames, function () {
        var attrName = this;
        var thisDefer = $.Deferred();

        deferredObjects.push(thisDefer);
        site.onLoadRpc.requests.push({
          method: 'user.getAttOption',
          params: [
            {
              attribute_name: attrName,
              select_type: 'checked',
              _TOKEN: $.cookie('csrftoken')
            }
          ],
          onSuccess: function (data) {
            thisDefer.resolve(data.result.value);
          },
          onError: function () {}
        });
      });
    }
  };

  // --------
  // Callbacks and whatnot

  site.offers.welcomeoffer = {
    behavior: Drupal.behaviors.ELB_welcomeoffer,

    getOverlayContent: function () {
      return $('#cboxLoadedContent');
    },

    /**
     * Initialize the signup form
     */
    initSignUpForm: function () {
      var self = site.offers.welcomeoffer,
        overlay = self.getOverlayContent();
      var interestSectionInputNames =
        Drupal.settings.common.interest_sections_input_names || 'PERFUME_AS_AN_ART,KILIAN_MY_KIND_OF_LOVE';
      var attrNames = interestSectionInputNames.split(',');

      // Fetch the RPC results and generate the user interest Section
      $.when.apply($, deferredObjects).then(function (data1, data2) {
        var checkboxData = data1 && data2 ? $.merge($.merge([], data1), data2) : data1;

        if (checkboxData !== null && checkboxData.length > 0) {
          var $jsMostIntersted = $('.user_interests');

          $.each(checkboxData, function (ele, index) {
            var checkboxContainer = $('<div/>').addClass('teaser-check').appendTo($jsMostIntersted);

            $('<input/>').val(index.value).attr({
              type: 'checkbox',
              name: attrNames[ele]
            }).appendTo(checkboxContainer);
            $('<label/>').text(index.label).appendTo(checkboxContainer);
          });
        }
      });

      $('.welcomeoffer-overlay #cboxContent, #cboxOverlay').fadeOut(1, function () {
        $('#cboxOverlay')
          .css('visibility', 'visible')
          .fadeIn(500, function () {
            $('.welcomeoffer-overlay #cboxContent').css('visibility', 'visible').fadeIn(100);
          });
      });

      // Load different BG Image for Millennial Menu
      var $is_millennial = $('.millennial-mpp__section').length;
      var other_bg_image = $('.bg-img-others').val();
      var millenial_bg_image = $('.bg-img-millennial').val();
      var bgImage = $is_millennial ? millenial_bg_image : other_bg_image;
      var mobile_bg_image = $('.mobile-img-others').val();
      var millenial_mobile_image = $('.mobile-img-millennial').val();
      var mobileImage = $is_millennial ? millenial_mobile_image : mobile_bg_image;

      $('.popover-email-signup').css('background-image', 'url(' + bgImage + ')');
      $('.site-email-signup-bg').attr('src', mobileImage);

      overlay.find('form').submit(function (e) {
        e.preventDefault();

        var form = this;
        var paramObj = {};

        $.each($(form).serializeArray(), function (index, kv) {
          paramObj[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
        });

        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function (jsonRpcResponse) {
            var response = jsonRpcResponse.getData(),
              userinfo = response.userinfo || {};
            var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;
            var b = self.behavior,
              t = b.templates,
              c = t.error, // Default template to return
              e = true, // Defaults to the error condition
              d = { message: "<p>There's been an error.</p>" }; // Error message

            c = t.thanks;
            // And make sure there's no error to show
            e = false;
            overlay.empty().append(e ? b.getTemplateContent(c, d) : b.getTemplateContent(c));
            $.colorbox.resize();
            $('#colorbox').addClass('success');
            // Act like we're opening a new colorbox
            $(document).trigger('cbox_complete');
          },
          onFailure: function (jsonRpcResponse) {
            var messages = jsonRpcResponse.getMessages();
            // Store messages and display them in error ul element.
            var $errorMessages = $('.js-popup-offer-error-messages');
            var $fnameInput = $('.js-input-first-name');
            var $lnameInput = $('.js-input-last-name');
            var $emailInput = $('.js-input-pc-email');
            var $bdaySelect = $('.js-select-birth-day');
            var $bmonthSelect = $('.js-select-birth-month');
            var $byearSelect = $('.js-select-birth-year');

            $errorMessages.empty();

            // TODO: these need context
            $('input').removeClass('error');
            $('select').removeClass('error');

            var errorOrder = [
              'required.first_name.email_signup',
              'required.last_name.email_signup',
              'required.pc_email_address.email_signup',
              'invalid.pc_email_address.email_signup',
              'required.birth_month.email_signup',
              'required.birth_day.email_signup',
              'required.birth_year.email_signup'
            ];

            messages.sort(function (a, b) {
              return errorOrder.indexOf(a.key) - errorOrder.indexOf(b.key);
            });

            for (var i = 0; i < messages.length; i++) {
              var myErr = messages[i];

              if (myErr && myErr.key) {
                $errorMessages.removeClass('hidden');
                if (myErr.key === 'required.first_name.email_signup') {
                  $errorMessages.append('<li>' + messages[i].text + '</li>');
                  $fnameInput.addClass('error');
                }
                if (myErr.key === 'required.last_name.email_signup') {
                  $errorMessages.append('<li>' + messages[i].text + '</li>');
                  $lnameInput.addClass('error');
                }
                if (
                  myErr.key === 'required.pc_email_address.email_signup' ||
                  myErr.key === 'invalid.pc_email_address.email_signup'
                ) {
                  $errorMessages.append('<li>' + messages[i].text + '</li>');
                  $emailInput.addClass('error');
                }
                if (myErr.key === 'required.birth_month.email_signup') {
                  $errorMessages.append('<li>' + messages[i].text + '</li>');
                  $bmonthSelect.addClass('error');
                }
                if (myErr.key === 'required.birth_day.email_signup') {
                  $errorMessages.append('<li>' + messages[i].text + '</li>');
                  $bdaySelect.addClass('error');
                }
                if (myErr.key === 'required.birth_year.email_signup') {
                  $errorMessages.append('<li>' + messages[i].text + '</li>');
                  $byearSelect.addClass('error');
                }
              }
            }
            $.colorbox.resize();
          }
        });

        return false;
      });
    }
  };
})(jQuery);
